.rbc-toolbar {
  display: none;
}

.rbc-header {
  border-bottom: none;
  overflow: unset;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: none;
}

.rbc-today {
  background-color: unset;
}

.rbc-header + .rbc-header {
  border-left: none;
}
.rbc-time-header-content {
  border-left: none;
}

.rbc-time-view {
  border: none;
}
.rbc-time-content > * + * > * {
  border-left: none;
}
.rbc-time-content {
  border-top: none;
}
.rbc-time-slot {
  font-family: 'Poppins', sans-serif !important;
  color: black;
  font-weight: 500;
  font-size: 16px;
}
.rbc-timeslot-group {
  border-bottom: none;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #d8d8d8;
  position: relative;
  top: 12px;
}

.rbc-day-slot .rbc-time-slot:nth-child(even) {
  border-top: none;
}
.rbc-current-time-indicator {
  background-color: unset;
}
.rbc-day-slot .rbc-event-label{
    display:none;
}
.rbc-day-slot .rbc-event{
    border:none;
    margin-top: 8.5px;
}
.rbc-event{
    background-color:unset;
}
.rbc-time-column .rbc-timeslot-group {
    margin-bottom: 40px;
}
.rbc-time-view .rbc-allday-cell {
    padding-top:65px;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: unset;
}
.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  outline: none;
}