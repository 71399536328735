.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--weekend:disabled {
  color: rgba(16, 16, 16, 0.3);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  @media only screen and (max-width: 600px) {
    font-size: 10px;
  }
}

.react-calendar__tile:disabled {
  background-color: #fff;
}

.react-calendar__tile--hasActive {
  border-radius: 16px;
  color: #fff;
  background: #2B3D7D;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2B3D7D;
  border-radius: 16px;
  color: white;
}

.react-calendar {
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 4px;
  /* box-shadow: 0px 25px 35px 0px rgba(0, 0, 0, 0.1); */
  font-family: Arial, Helvetica, sans-serif;
  /* line-height: 1.125em; */
}
.react-calendar__navigation button {
  color: #050505;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
  @media only screen and (max-width: 600px) {
    font-size: 10px;
    min-width: 14%;
  }
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #99ceff;
  color: #fff;
  border-radius: 16px;
}
.react-calendar__tile--now {
  background: #cce6ff;
  border-radius: 6px;
  font-weight: bold;
  color: #2B3D7D;
  @media only screen and (max-width: 600px) {
    font-size: 10px;
  }
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #4da9ff;
  border-radius: 6px;
  font-weight: bold;
  color: #fff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #2B3D7D;
}
.react-calendar__tile--active {
  border-radius: 16px;
  background: #2B3D7D;
  font-weight: bold;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: rgba(52, 146, 159, 0.16);
}
.react-calendar__tile--range {
  background: #f5faff;
  color: #2B3D7D;
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  border-radius: 16px;
  background: #2B3D7D;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-radius: 16px;
  background: #2B3D7D;
  color: white;
}
