.rbc-day-slot {
  position: static;
}


.rbc-event {
  position: absolute;
}

.rbc-day-slot .rbc-events-container {
  margin-left: 76px;
  margin-top: 14px;
}

.rbc-events-container {
  display: grid;
  grid-template-rows: repeat(24, 80px);
}

.rbc-day-slot .rbc-event {
  position: static;
}

@media (max-width: 1200px) {
  .rbc-time-content {
    overflow-x: auto;
  }
  .rbc-events-container {
    grid-template-columns: repeat(7, 362px);
  }
}

@media (max-width: 600px) {
  .rbc-label.rbc-time-header-gutter {
    display: none; 
  }

  .rbc-time-view .rbc-row {
    overflow-x: scroll;
    overflow-y: hidden;
    min-height: 110px;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }
  .rbc-time-view .rbc-row::-webkit-scrollbar { 
    display: none; 
  }

  .rbc-header {
    height: 0;
  }

  .rbc-row-segment {
    margin-bottom: 6px; 
    max-width: 100% !important;
  }

  /* .rbc-day-slot .rbc-event-content {
    width: 50%;
  } */
}
